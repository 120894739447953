import React from "react";
import { t } from "../../../../i18n";
import Top from "../../../General/Blocks/Top/Top";
import "./AllServices.scss";
import ServiceBlock from "./ServiceBlock";

function AllServices() {
  return (
    <>
      <Top
        scrollId={"allServices"}
        header={t("SolutionsForYourEveryHeadache")}
        text={t("VarietyOfServices")}
        image="/shape2.png"
      />
      <section className="allServices" id="allServices">
        <div className="container">
          <div className="allServices-content">
            <ServiceBlock
              bulb
              header={t("WebDesignAndDevelopment")}
              text={t("WebDesignAndDevelopmentParagraph")}
              image="/servicesWeb.png"
              link="/web"
              // link="/services/web"
            />
            <ServiceBlock
              imageRightColumn
              header={t("LogoDesign")}
              text={t("LogoDesignParagraph")}
              image="/servicesLogo.png"
              link="/logo"
              // link="/services/logo"
            />
          </div>
        </div>
      </section>
    </>
  );
}

export default AllServices;
