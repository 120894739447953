import React from "react";
import { t } from "../../../../i18n";
import PortfolioProjectsItem from "./PortfolioProjectsItem";

function PortfolioProjects() {
  return (
    <section className="portfolioProjects" id="portfolioProjects">
      <div className="container">
        <div className="portfolioProjects-list">
          <PortfolioProjectsItem
            link="https://kiwishop.md"
            image="/kiwishop2.png"
            header="KiWi Shop"
            service={t("PortfolioWebDesignAndDevelopment")}
            texts={[
              t("KiwiShopP1"),
              t("KiwiShopP2"),
              t("KiwiShopP3"),
              t("KiwiShopP4"),
            ]}
          />
          <PortfolioProjectsItem
            imageRightColumn
            link="https://kopeika.ee"
            image="/kopeika.png"
            header="Kopeika"
            service={t("PortfolioWebDesignAndDevelopment")}
            texts={[t("KopeikaP1"), t("KopeikaP2")]}
          />
          <PortfolioProjectsItem
            link="https://victordulap.com"
            image="/vd.png"
            header={`Victor’s portfolio`}
            service={t("PortfolioWebDesign")}
            texts={[t("VictorsP1"), t("VictorsP2")]}
          />
          <PortfolioProjectsItem
            imageRightColumn
            link="https://vd-clothes.vercel.app/"
            image="/vdclothes.png"
            header="VD Clothes"
            service={t("PortfolioWebDesignAndDevelopment")}
            texts={[t("VDClothesP1"), t("VDClothesP2"), t("VDClothesP3")]}
          />
        </div>
      </div>
    </section>
  );
}

export default PortfolioProjects;
