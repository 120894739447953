import { LOCALES } from "../locales";

const translationKeys = {
  [LOCALES.ENGLISH]: {
    // NAVIGATION and GENERAL
    Home: "Home",
    Services: "Services",
    Portfolio: "Portfolio",
    AboutUs: "About us",
    WorkWithUs: "Work with us",
    ContactUs: "Contact us",
    MoreInfo: "More info",
    // HERO-HOME
    InNeedOfAWebsite: "In need of a website?",
    WeWillHelpYou: "We will help you.",
    // GENERAL INFO
    ProfessionalsInTheField: "Professionals in the field.",
    // PROBLEMS
    WeWillSolveYourProblems: "We will solve your problems.",
    TrixmasIsATeamOf:
      "Trixmas is a team of talented developers and designers, bringing dreams and ideas into reality.",
    // SERVICES
    OurServices: "Our services",
    WebsiteCreation: "Website creation",
    LogoDesign: "Logo design",
    ForMoreServices: "For more services",
    ClickHereMoreServices: "Click here",
    // PROJECTS
    ProjectsWeHaveWorkedOn: "Projects we have worked on",
    AndOtherMoreHere: "And other more here",
    // CONTACT FORM
    ForMoreQuestions: "For more questions",
    LeaveAMessage: "Leave a message",
    FirstName: "First name",
    LastName: "Last name",
    Email: "E-Mail",
    Phone: "Phone",
    Message: "Message",
    LeaveYourMessageHere: "Leave your message here",
    OurContacts: "Our contacts",
    SendMessage: "Send message",
    GetInTouchWithUs: "Get in touch with us",
    // BLOCKS
    ScrollDown: "Scroll down",
    WebDesignAndDevelopment: "Web design & development",
    ElevateYourDigitalPresence:
      "Elevate Your Digital Presence with our solutions.",
    MakingTheFaceOfYourBrandShine: "Making the face of your brand shine ✨",
    // WHAT DO WE OFFER
    WhatDoWeOffer: "What do we offer?",
    // WHAT DO WE OFFER - WEB CREATION
    WebDesign1Header: "🌐 Custom web design",
    WebDesign1Text:
      "Your website is your digital storefront, and it should stand out. We create stunning, tailor-made web designs that captivate your audience and reflect your brand's essence.",
    WebDesign2Header: "🔨 Web Development",
    WebDesign2Text:
      "Behind every beautiful design is powerful functionality. Our team of expert developers ensures your website not only looks great but functions flawlessly, offering seamless user experiences.",
    WebDesign3Header: "🚀 Responsive Design",
    WebDesign3Text:
      "In an era of mobile browsing, responsiveness is non-negotiable. We craft websites that adapt to every device, ensuring your content looks brilliant no matter where your visitors are.",
    WebDesign4Header: "🧩 User-Centric Approach",
    WebDesign4Text:
      "Your users' needs and expectations are at the forefront of our design process. We build websites that are intuitive, easy to navigate, and keep visitors engaged.",
    WebDesign5Header: "🔒 Security & Performance",
    WebDesign5Text:
      "We understand the importance of keeping your online presence safe. Our websites are fortified with the latest security measures, and we optimize performance for lightning-fast load times.",
    WebDesign6Header: "💡 Innovative Solutions",
    WebDesign6Text:
      "Your business is unique, and your website should reflect that. We don't just create websites; we create solutions that drive results, from simple landing pages to e-commerce & more.",
    // WHAT DO WE OFFER - LOGO DESIGN
    LogoDesign1Header: "🎨 Custom Logo Design",
    LogoDesign1Text:
      "We believe in the power of uniqueness. Our talented designers will work closely with you to create a logo that embodies your brand's personality and values.",
    LogoDesign2Header: "🧐 Market Research",
    LogoDesign2Text:
      "Understanding your industry and target audience is crucial. We conduct in-depth market research to ensure your logo design is not only eye-catching but also strategically effective.",
    LogoDesign3Header: "🎯 Versatility",
    LogoDesign3Text:
      "Your logo should work across various platforms and applications. We design logos that look fantastic in both print and digital formats.",
    LogoDesign4Header: "🔑 Memorability",
    LogoDesign4Text:
      "We understand that a memorable logo is a powerful asset. We strive to create logos that leave a lasting impression on your audience.",
    // PRICE
    StartingPriceLong: "All of this, at a starting price of ",
    StartingPriceShort: "At a starting price of",
    only: "only ",
    // WORK WITH US
    LetsWorkTogether: "Let’s work together.",
    // Portfolio
    LatestProjects: "Some of the latest projects we have worked on.",
    LetsConnect: "Let's Connect and Create Together",
    PortfolioWebDesignAndDevelopment: "Web design and development",
    PortfolioWebDesign: "Web design",
    KiwiShopP1:
      "Kiwi Shop is an ecommerce web application built by TRIXMAS, developed to cater to the needs of pet lovers.",
    KiwiShopP2:
      "We've boosted Kiwi Shop sales massively (500%) by moving it online.",
    KiwiShopP3:
      "Users can easily navigate the zoo-shop online, switch their prefered language, add wanted items in the cart and check out the cart.",
    KiwiShopP4:
      "The user interface and experience of Kiwi Shop were meticulously designed to exude a sense of warmth and delight, while seamlessly aligning with the preferences of the typical client browsing the shop.",
    VictorsP1:
      "Victor wanted a simple, minimal, but working design for his portfolio.",
    VictorsP2: "And that’s what our team executed for him.",
    KopeikaP1:
    "A project where we had the privilege to collaborate with Kopeika Networks OÜ, a leading player in the SMS services arena with a solid presence across Eastern Europe and the Baltic countries.",
    KopeikaP2:
    "As their dedicated partner, we contributed to the design and creation block by block for their website, which converts customers.",
    VDClothesP1: "VD Clothes is an ecommerce web application built from scratch, showcasing the latest fashion trends and clothing styles.",
    VDClothesP2: "Users can easily browse through the app's collection of stylish clothing items, add them to their cart, and checkout.",
    VDClothesP3: "With its sleek design and easy-to-use interface, VD Clothes promises an enjoyable shopping experience for fashion enthusiasts everywhere.",
    VisitWebsite: "Visit website",
    // SERVICES PAGE
    SolutionsForYourEveryHeadache: "Solutions for your every headache.",
    VarietyOfServices:
      "Trixmas provides a variety of services, to fulfill every customer’s needs.",
    WebDesignAndDevelopmentParagraph:
      "In the ever-evolving landscape of the digital world, your online presence matters more than ever. At TRIXMAS, we're passionate about crafting unique and impactful digital experiences that transform your vision into reality. Our Web Design & Development services are your gateway to a dynamic, engaging, and visually stunning online presence.",
    LogoDesignParagraph:
      "Your logo is the face of your brand, the first impression that captures the essence of your business in a single glance. At TRIXMAS, we specialize in crafting distinctive logos that make a lasting impact. Our Logo Design services are the key to creating a visual identity that resonates with your target audience and sets you apart from the competition.",
    // ABOUT US
    ABOUTTR: "ABOUT ",
    YourSuccessIsOurSuccess:
      "Your success is our success. We work closely with you, ensuring your vision is realized.",
    OurMission: "Our Mission",
    OurMissionParagraph:
      "At TRIXMAS, our mission is clear: to empower businesses to reach their full digital potential. We understand the unique challenges that businesses face in a rapidly changing online environment. Our goal is to provide solutions that keep you ahead of the curve.",
    OurTeam: "Our team",
    OurTeamP1:
      "Our success is a testament to the brilliance and dedication of our team.",
    OurTeamP2: "Talented developers and designers collaborating with you.",
    OurTeamP3:
      "We are using the latest technologies like React, Redux, Node.js, Figma and AI.",
    OurTeamP4: "To us challenges are welcomed with open arms.",
    WhyUs: "Why Choose TRIXMAS?",
    WhyUsP1: "Competence",
    WhyUsP2: "Client-Centric Approach",
    WhyUsP3: "Tailored Solutions",
    WhyUsP4: "Affordability",
  },
};

export default translationKeys;
