import React from "react";
import "./Hero.scss";
import WhiteButton from "../../../General/Buttons/WhiteButton";
import { t } from "../../../../i18n";
import MoreInfoButton from "../../../General/Buttons/MoreInfoButton";

function Hero() {
  return (
    <section className="hero">
      <div className="container">
        <div className="block hero-content padding">
          <section className="column column-text">
            <h1>{t("InNeedOfAWebsite")}</h1>
            <p>{t("WeWillHelpYou")}</p>
            <div className="hero-buttons">
              <WhiteButton
                scrollId={"contactUs-header"}
                text={t("ContactUs")}
              />
              <MoreInfoButton link="/about" color="transparent" />
            </div>
          </section>
          <section className="column column-image">
            <img src="./images/shape.png" alt="" />
          </section>
        </div>
      </div>
    </section>
  );
}

export default Hero;
