import React from "react";
import AnimatedWrapper from "../../../../helpers/components/AnimatedWrapper";
import { t } from "../../../../i18n";
import ServiceItem from "./ServiceItem";
import "./Services.scss";

function Services() {
  return (
    <section className="services">
      <div className="container padding">
        <AnimatedWrapper baseClasses={"bottomSA"}>
          <h2>{t("OurServices")}</h2>
        </AnimatedWrapper>
        <AnimatedWrapper baseClasses={"services-list bottomSA"}>
          <div className="services-list">
            <ServiceItem title={t("WebsiteCreation")} price="249" link="/web" />
            <ServiceItem title={t("LogoDesign")} price="99" link="/logo" />
            {/* <ServiceItem title={t("WebsiteCreation")} price="249" link="/services/web" />
          <ServiceItem title={t("LogoDesign")} price="99" link="/services/logo" /> */}
            {/* <ServiceItem title={t("ForMoreServices")} moreServices link="/services" /> */}
          </div>
        </AnimatedWrapper>
      </div>
    </section>
  );
}

export default Services;
