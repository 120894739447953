import React from "react";
import { Link } from "react-router-dom";

function WhiteButton({ text, link, closeMobileMenu, scrollId }) {
  const storedLanguage = localStorage.getItem("selectedLanguage") || "en";

  const handleScroll = () => {
    const scrollSection = document.getElementById(scrollId);
    if (scrollSection) {
      scrollSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClick = () => {
    if (closeMobileMenu) {
      closeMobileMenu();
    }
    if (scrollId) {
      handleScroll();
    }
  };

  return (
    <Link to={link}>
      <button
        className={`btn whiteButton ${storedLanguage}`}
        onClick={handleClick}
      >
        {text}
      </button>
    </Link>
  );
}

export default WhiteButton;
