import React from "react";
import AnimatedWrapper from "../../../../helpers/components/AnimatedWrapper";
import { t } from "../../../../i18n";

function WhyUs() {
  return (
    <section className="whyUs">
      <div className="container">
        <div className="whyUs-content block">
          <AnimatedWrapper baseClasses={"leftSA"}>
            <h2>{t("WhyUs")}</h2>
          </AnimatedWrapper>
          <AnimatedWrapper baseClasses={"whyUs-list leftSA"}>
            <div className="whyUs-list">
              <article className="whyUs-item">
                <h4>{t("WhyUsP1")}</h4>
              </article>
              <article className="whyUs-item">
                <h4>{t("WhyUsP2")}</h4>
              </article>
              <article className="whyUs-item">
                <h4>{t("WhyUsP3")}</h4>
              </article>
              <article className="whyUs-item">
                <h4>{t("WhyUsP4")}</h4>
              </article>
            </div>
          </AnimatedWrapper>
        </div>
      </div>
    </section>
  );
}

export default WhyUs;
