import React from "react";
import ArrowButton from "../../Buttons/ArrowButton";
import "./Tops.scss";

function TopV2({ header, image, marginBottom, logo, scrollId }) {
  return (
    <section className={marginBottom ? "topV2 marginBottom" : "topV2"}>
      <div className="container">
        <div className="block topV2-content padding">
          <img src={`./images${image}`} alt={header} />
          <div className="topV2-headerContainer">
            <h1>{header}</h1>
            {logo && <img src="./images/Logo.svg" alt={header} />}
          </div>
          <ArrowButton scrollId={scrollId} />
        </div>
      </div>
    </section>
  );
}

export default TopV2;
