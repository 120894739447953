import React from "react";
import AnimatedWrapper from "../../../../helpers/components/AnimatedWrapper";
import { t } from "../../../../i18n";
import OfferItem from "./OfferItem";
import "./Offers.scss";

function Offers({ offerItems }) {
  return (
    <section className="offers">
      <div className="container">
        <div className="offers-content padding">
          <AnimatedWrapper baseClasses={"bottomSA"}>
            <h2 id="offers">{t("WhatDoWeOffer")}</h2>
          </AnimatedWrapper>
          <div className="offers-list">
            {offerItems.map((item, index) => (
              <OfferItem
                key={index}
                header={t(item.header)}
                description={t(item.text)}
              />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Offers;
