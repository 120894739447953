import React from "react";
import AnimatedWrapper from "../../../../helpers/components/AnimatedWrapper";
import { t } from "../../../../i18n";
import "./Price.scss";

function Price({ price }) {
  return (
    <AnimatedWrapper baseClasses={"price padding bottomSA"}>
      <section className="price padding">
        <div className="container">
          <div className="price-content">
            <AnimatedWrapper baseClasses={"price-header bottomSA"}>
              <h2 className="price-header">{t("StartingPriceLong")}</h2>
            </AnimatedWrapper>
            <AnimatedWrapper baseClasses={"price-headerMobile bottomSA"}>
              <h2 className="price-headerMobile">{t("StartingPriceShort")}</h2>
            </AnimatedWrapper>
            <AnimatedWrapper baseClasses={"bottomSA"}>
              <h3>
                {t("only")} ${price}
              </h3>
            </AnimatedWrapper>
          </div>
        </div>
      </section>
    </AnimatedWrapper>
  );
}

export default Price;
