import React from "react";
import AnimatedWrapper from "../../../../helpers/components/AnimatedWrapper";

function OfferItem({ header, description }) {
  return (
    <article className="offers-item">
    <AnimatedWrapper baseClasses={"bottomSA"}>
      <h4>{header}</h4>
    </AnimatedWrapper>
    <AnimatedWrapper baseClasses={"bottomSA"}>
      <p>{description}</p>
    </AnimatedWrapper>
    </article>
  );
}

export default OfferItem;
