// IntersectionObserverComponent.js
import React, { useEffect } from "react";

const IntersectionObserverComponent = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        // console.log(entry);
        if (entry.isIntersecting) {
          entry.target.classList.add("showSA");
        } 
        // else {
        //   entry.target.classList.remove("showSA");
        // }
      });
    }); //, {threshold: 0.2}

    const hiddenElements = document.body.querySelectorAll(".hiddenSA");
    hiddenElements.forEach((el) => observer.observe(el));

    // Cleanup observer on component unmount
    return () => {
      hiddenElements.forEach((el) => observer.unobserve(el));
    };
  }, []); // Empty dependency array ensures the effect runs once on mount

  return null; // This component doesn't render anything
};

export default IntersectionObserverComponent;
