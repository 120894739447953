import { Link } from "react-router-dom";
import React, { useState } from "react";
import TransparentButton from "../General/Buttons/TransparentButton";
import WhiteButton from "../General/Buttons/WhiteButton";
import Logo from "../General/Logo/Logo";
import "./Header.scss";
import { t } from "../../i18n";
import LanguageSwitch from "./LanguageSwitch/LanguageSwitch";

function Header({ selectedLanguage, setSelectedLanguage }) {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const openMobileMenu = () => {
    setMobileMenuOpen(true);
  };
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
  };

  return (
    <header className="header">
      <nav className="navbar">
        <div className="container">
          <div className="navbar-content">
            <Logo />
            <div className="navbar-right">
              <ul
                className={
                  mobileMenuOpen ? "navbar-menu--active" : "navbar-menu"
                }
              >
                <section className="navbar-menu-header">
                  <img
                    src="./images/SmallLogo.svg"
                    alt="logo"
                    className="navbar-menu-logo"
                  />
                  <div
                    className="navbar-menuMobile-close"
                    onClick={closeMobileMenu}
                  >
                    <img src="./images/meat.svg" alt="close mobile menu" />
                  </div>
                </section>
                <li className="navbar-item navbar-item--mobile">
                  <TransparentButton link="/" text={t("Home")} closeMobileMenu={closeMobileMenu} />
                </li>
                <li className="navbar-item">
                  <TransparentButton link="/services" text={t("Services")} closeMobileMenu={closeMobileMenu} />
                </li>
                <li className="navbar-item">
                  <TransparentButton link="/portfolio" text={t("Portfolio")} closeMobileMenu={closeMobileMenu} />
                </li>
                <li className="navbar-item">
                  <TransparentButton link="/about" text={t("AboutUs")} closeMobileMenu={closeMobileMenu} />
                </li>
                <li className="navbar-item">
                  <WhiteButton link="/workwithus" text={t("WorkWithUs")} closeMobileMenu={closeMobileMenu} />
                </li>
                <section className="navbar-menu-footer">
                  <LanguageSwitch
                    mobile
                    selectedLanguage={selectedLanguage}
                    setSelectedLanguage={setSelectedLanguage}
                  />
                </section>
              </ul>
              <LanguageSwitch
                selectedLanguage={selectedLanguage}
                setSelectedLanguage={setSelectedLanguage}
              />
            </div>
            <div className="navbar-menuMobile" onClick={openMobileMenu}>
              <img src="./images/burger.svg" alt="open mobile menu" />
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
