import React from "react";
import AnimatedWrapper from "../../../../helpers/components/AnimatedWrapper";
import { t } from "../../../../i18n";

function OurMission() {
  return (
    <section className="ourMission">
      <div className="container">
        <div className="ourMission-content padding block block-bulb">
          <AnimatedWrapper baseClasses={"column column-image leftSA"}>
            <section className="column column-image">
              <img src={`./images/shape6.png`} alt="Our Mission" />
            </section>
          </AnimatedWrapper>
          <AnimatedWrapper baseClasses={"column column-text rightSA"}>
            <section className="column column-text">
              <h2>{t("OurMission")}</h2>
              <p>{t("OurMissionParagraph")}</p>
            </section>
          </AnimatedWrapper>
        </div>
      </div>
    </section>
  );
}

export default OurMission;
