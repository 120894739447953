import React, { useEffect, useRef, useState } from "react";

const AnimatedWrapper = ({ children, baseClasses }) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      });
    });

    observer.observe(ref.current);

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  // Use a callback ref to pass the ref to the child component
  const childWithProps = React.cloneElement(children, {
    ref: (node) => {
      ref.current = node;
      // If the original ref prop exists on the child, call it as well
      if (typeof children.ref === "function") {
        children.ref(node);
      }
    },
    className: `${baseClasses} hiddenSA ${isVisible ? "showSA" : ""}`,
  });

  return <>{childWithProps}</>;
};

export default AnimatedWrapper;
