import React from "react";
import AnimatedWrapper from "../../../../helpers/components/AnimatedWrapper";
import MoreInfoButton from "../../../General/Buttons/MoreInfoButton";

function ServiceBlock({ bulb, imageRightColumn, header, text, image, link }) {
  return (
    <article
      className={
        bulb
          ? imageRightColumn
            ? "allServices-service padding block block-bulb reverseColumn768"
            : "allServices-service padding block block-bulb"
          : imageRightColumn
          ? "allServices-service padding block reverseColumn768"
          : "allServices-service padding block"
      }
    >
      {!imageRightColumn && (
        <AnimatedWrapper baseClasses={"column column-image leftSA"}>
          <section className="column column-image">
            <img src={`./images${image}`} alt={header} />
          </section>
        </AnimatedWrapper>
      )}

      <AnimatedWrapper
        baseClasses={
          imageRightColumn
            ? "column column-text rightSA"
            : "column column-text leftSA"
        }
      >
        <section className="column column-text">
          <h2>{header}</h2>
          <p>{text}</p>
          <div className="allServices-service-buttons">
            <MoreInfoButton link={link} color="white" />
          </div>
        </section>
      </AnimatedWrapper>
      {imageRightColumn && (
        <AnimatedWrapper baseClasses={"column column-image rightSA"}>
          <section className="column column-image">
            <img src={`./images${image}`} alt={header} />
          </section>
        </AnimatedWrapper>
      )}
    </article>
  );
}

export default ServiceBlock;
