import React, { useEffect } from "react";
import ContactUs from "../../General/ContactUs/ContactUs";
import Offers from "../../General/Blocks/Offers/Offers";
import Price from "../../General/Blocks/Price/Price";
import Top from "../../General/Blocks/Top/Top";
import { t } from "../../../i18n";

const offerItems = [
  { header: "WebDesign1Header", text: "WebDesign1Text" },
  { header: "WebDesign2Header", text: "WebDesign2Text" },
  { header: "WebDesign3Header", text: "WebDesign3Text" },
  { header: "WebDesign4Header", text: "WebDesign4Text" },
  { header: "WebDesign5Header", text: "WebDesign5Text" },
  { header: "WebDesign6Header", text: "WebDesign6Text" },
];

function WebCreation() {
  return (
    <>
      <Top
        scrollId={"offers"}
        header={t("WebDesignAndDevelopment")}
        text={t("ElevateYourDigitalPresence")}
        image="/shape2.png"
      />
      <Offers offerItems={offerItems} />
      <Price price="249" />
      <ContactUs header={t("GetInTouchWithUs")} />
    </>
  );
}

export default WebCreation;
