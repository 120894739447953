import React from "react";
import GrayButton from "../../../General/Buttons/GrayButton";
import { t } from "../../../../i18n";
import MoreInfoButton from "../../../General/Buttons/MoreInfoButton";

function ServiceItem({ title, price, moreServices, link }) {
  return (
    <article
      className={
        !moreServices
          ? "services-item column"
          : "services-item services-item-transparent column"
      }
    >
      <h3>{title}</h3>
      <div>
        {!moreServices && <p>Starting at ${price}</p>}
        {!moreServices ? (
          <MoreInfoButton link={link} color="gray" />
        ) : (
          <GrayButton link={link} text={t("ClickHereMoreServices")} />
        )}
      </div>
    </article>
  );
}

export default ServiceItem;
