import React, { useState } from "react";
import { t } from "../../../i18n";
import GrayButton from "../Buttons/GrayButton";
import "./ContactUs.scss";
import { FormattedMessage } from "react-intl";
import AnimatedWrapper from "../../../helpers/components/AnimatedWrapper";
import emailjs from 'emailjs-com'

function ContactUs({ header }) {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., sending data to a server)

    console.log(formData);
    emailjs.send('service_spn9lki', 'template_5j205cb', formData, 'BdA27rdtK6zqSGda7')
      .then((response) => {
        console.log('Email sent successfully:', response);
      })
      .catch((error) => {
        console.error('Failed to send email:', error);
      });
  };

  return (
    <section className="contactUs padding" id="contactUs">
      <AnimatedWrapper baseClasses={"container contactUsSA"}>
        <div className="container">
          {header && (
            <AnimatedWrapper baseClasses={"contactUsSA"}>
              <h2 id="contactUs-header">{header}</h2>
            </AnimatedWrapper>
          )}
          <div className="contactUs-content" id="contactUs-content">
            <form className="contactUs-form" onSubmit={handleSubmit}>
              <h3>{t("LeaveAMessage")}</h3>
              <div className="contactUs-form-row">
                <label htmlFor="firstName">{t("FirstName")}</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Patrick"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="contactUs-form-row">
                <label htmlFor="lastName">{t("LastName")}</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Star"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="contactUs-form-row">
                <label htmlFor="email">{t("Email")}</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="yourEmail@gmail.com"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="contactUs-form-row">
                <label htmlFor="phone">{t("Phone")}</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  placeholder="+373 799 543 21"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>

              <div className="contactUs-form-row">
                <label htmlFor="message">{t("Message")}</label>
                <textarea
                  id="message"
                  name="message"
                  placeholder="Leave your message here"
                  value={formData.message}
                  onChange={handleChange}
                  rows="7" // Adjust the number of rows as needed
                  required
                />
              </div>

              <GrayButton text={t("SendMessage")} type="submit" />
            </form>
            <div className="contactUs-infoBlock">
              <h3>{t("OurContacts")}</h3>
              <a href="mailto:contact@trixmas.com">contact@trixmas.com</a>
              <a href="tel:+37378509291">+373 785 092 91</a>
            </div>
          </div>
        </div>
      </AnimatedWrapper>
    </section>
  );
}

export default ContactUs;
