import React from "react";
import { t } from "../../../i18n";
import ContactUs from "../../General/ContactUs/ContactUs";
import GeneralInfo from "../Home/GeneralInfo/GeneralInfo";
import Hero from "../Home/Hero/Hero";
import Problems from "../Home/Problems/Problems";
import Projects from "../Home/Projects/Projects";
import Services from "../Home/Services/Services";

function Home() {
  return (
    <>
      <Hero />
      <GeneralInfo />
      <Problems />
      <Services />
      <Projects />
      <ContactUs header={t("ForMoreQuestions")} />
    </>
  );
}

export default Home;
