import React from "react";
import Top from "../../../General/Blocks/Top/Top";
import PortfolioProjects from "./PortfolioProjects";
import "./Portfolio.scss";
import ContactUs from "../../../General/ContactUs/ContactUs";
import { t } from "../../../../i18n";

function Portfolio() {
  return (
    <>
      <Top
        scrollId={"portfolioProjects"}
        marginBottom
        header={t("Portfolio")}
        text={t("LatestProjects")}
        image="/shape.png"
      />
      <PortfolioProjects />
      <ContactUs header={t("LetsConnect")} />
    </>
  );
}

export default Portfolio;
