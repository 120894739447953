import React from "react";
import { t } from "../../../../i18n";
import WhiteButton from "../../Buttons/WhiteButton";
import WhiteButtonScrollDown from "../../Buttons/WhiteButtonScrollDown";

function Top({ header, text, image, marginBottom, scrollId }) {
  return (
    <section className={marginBottom ? "top marginBottom" : "top"}>
      <div className="container">
        <div className="block padding">
          <section className="column column-text">
            <h1>{header}</h1>
            <p>{text}</p>
            <div className="top-buttons">
              <WhiteButtonScrollDown scrollId={scrollId} />
            </div>
          </section>
          <section className="column column-image">
            <img src={`./images${image}`} alt="Page top decoration" />
          </section>
        </div>
      </div>
    </section>
  );
}

export default Top;
