import React, { useEffect } from "react";
import { useState } from "react";
import "./LanguageSwitch.scss";

function LanguageSwitch({ selectedLanguage, setSelectedLanguage, mobile }) {
  const [dropDownOn, setDropDownOn] = useState(false);

  useEffect(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, [setSelectedLanguage]);

  const handleChangeLanguage = (code) => {
    setSelectedLanguage(code);
    setDropDownOn(false);
    localStorage.setItem("selectedLanguage", code);
  };

  return (
    <div
      className={mobile ? "language language--mobile" : "language"}
      onMouseEnter={() => setDropDownOn(true)}
      onMouseLeave={() => setDropDownOn(false)}
    >
      <span className="language-selected">
        {selectedLanguage}{" "}
        <img
          src={dropDownOn ? "./images/caret-up.svg" : "./images/caret-down.svg"}
          alt="Language caret"
        />
      </span>
      {dropDownOn && (
        <ul className="language-list">
          <li
            key="en"
            className={
              selectedLanguage === "en"
                ? "language-item--active"
                : "language-item"
            }
          >
            <button onClick={() => handleChangeLanguage("en")}>En</button>
          </li>
          <li
            key="ro"
            className={
              selectedLanguage === "ro"
                ? "language-item--active"
                : "language-item"
            }
          >
            <button onClick={() => handleChangeLanguage("ro")}>Ro</button>
          </li>
          <li
            key="ru"
            className={
              selectedLanguage === "ru"
                ? "language-item--active"
                : "language-item"
            }
          >
            <button onClick={() => handleChangeLanguage("ru")}>Ru</button>
          </li>
        </ul>
      )}
    </div>
  );
}

export default LanguageSwitch;
