import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { t } from "../../../i18n";

function MoreInfoButton({ link, color }) {
  const storedLanguage = localStorage.getItem("selectedLanguage") || "en";


  return (
    <Link to={link}>
      <button className={`btn ${color}Button moreInfo ${storedLanguage}`}>
        {t("MoreInfo")}
      </button>
    </Link>
  );
}

export default MoreInfoButton;
