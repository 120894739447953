import { Link } from "react-router-dom";
import React from "react";
import TransparentButton from "../General/Buttons/TransparentButton";
import WhiteButton from "../General/Buttons/WhiteButton";
import Logo from "../General/Logo/Logo";
import "./Footer.scss";
import { t } from "../../i18n";
import LanguageSwitch from "../Header/LanguageSwitch/LanguageSwitch";

function Footer({ selectedLanguage, setSelectedLanguage }) {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
          <Logo />
          <div className="footer-right">
            <LanguageSwitch
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
            <LanguageSwitch
              mobile
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
            />
            <ul className="footer-nav">
              <li className="footer-item">
                <WhiteButton link="/workwithus" text={t("WorkWithUs")} />
              </li>
              <li className="footer-item">
                <TransparentButton link="/services" text={t("Services")} />
              </li>
              <li className="footer-item">
                <TransparentButton link="/portfolio" text={t("Portfolio")} />
              </li>
              <li className="footer-item">
                <TransparentButton link="/about" text={t("AboutUs")} />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
