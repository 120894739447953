import React from "react";
import { t } from "../../../i18n";
import Offers from "../../General/Blocks/Offers/Offers";
import Price from "../../General/Blocks/Price/Price";
import Top from "../../General/Blocks/Top/Top";
import ContactUs from "../../General/ContactUs/ContactUs";

const offerItems = [
  { header: "LogoDesign1Header", text: "LogoDesign1Text" },
  { header: "LogoDesign2Header", text: "LogoDesign2Text" },
  { header: "LogoDesign3Header", text: "LogoDesign3Text" },
  { header: "LogoDesign4Header", text: "LogoDesign4Text" },
];

function LogoDesign() {
  return (
    <>
      <Top
        scrollId={"offers"}
        header={t("LogoDesign")}
        text={t("MakingTheFaceOfYourBrandShine")}
        image="/shape3.png"
      />
      <Offers offerItems={offerItems} />
      <Price price="99" />
      <ContactUs header={t("GetInTouchWithUs")} />
    </>
  );
}

export default LogoDesign;
