import React from "react";
import { Link } from "react-router-dom";
import AnimatedWrapper from "../../../../helpers/components/AnimatedWrapper";
import { t } from "../../../../i18n";
import ProjectItem from "./ProjectItem";
import "./Projects.scss";

function Projects() {
  return (
    <section className="projects">
      <div className="container">
        <div className="projects-content padding">
          <AnimatedWrapper baseClasses={"topSA"}>
            <h2>{t("ProjectsWeHaveWorkedOn")}</h2>
          </AnimatedWrapper>
          <AnimatedWrapper baseClasses={"projects-list topSA"}>
            <div className="projects-list">
              <ProjectItem
                name="KiWi Shop"
                link="https://kiwishop.md"
                image="kiwishop.png"
              />
              <ProjectItem
                name="Kopeika"
                link="https://kopeika.ee/"
                image="kopeika.png"
              />
            </div>
          </AnimatedWrapper>
          <AnimatedWrapper baseClasses={"linkDecoration topSA"}>
            <h4 className="linkDecoration">
              <Link to="/portfolio">{t("AndOtherMoreHere")}</Link>
            </h4>
          </AnimatedWrapper>
        </div>
      </div>
    </section>
  );
}

export default Projects;
