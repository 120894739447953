import React from "react";
import { t } from "../../../../i18n";

function Success() {
  return (
    <section className="success" id="success">
      <h2>{t("YourSuccessIsOurSuccess")}</h2>
    </section>
  );
}

export default Success;
