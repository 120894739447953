import React from "react";
import { t } from "../../../../i18n";
import TopV2 from "../../../General/Blocks/Top/TopV2";
import ContactUs from "../../../General/ContactUs/ContactUs";
import "./AboutUs.scss";
import OurMission from "./OurMission";
import Success from "./Success";
import Team from "./Team";
import WhyUs from "./WhyUs";

function AboutUs() {
  return (
    <>
      <TopV2 scrollId={"success"} header={t("ABOUTTR")} image="/shape5.png" logo />
      <Success />
      <OurMission />
      <Team />
      <WhyUs />
      <ContactUs header={t("LetsConnect")} />
    </>
  );
}

export default AboutUs;
