import React from "react";
import { t } from "../../../../i18n";
import "./GeneralInfo.scss";

function GeneralInfo() {
  return (
    <section className="generalInfo">
      <h2>{t("ProfessionalsInTheField")}</h2>
    </section>
  );
}

export default GeneralInfo;
