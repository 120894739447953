import React from "react";
import { t } from "../../../../i18n";
import TopV2 from "../../../General/Blocks/Top/TopV2";
import ContactUs from "../../../General/ContactUs/ContactUs";

function WorkWithUs() {
  return (
    <>
      <TopV2
        scrollId={"contactUs"}
        header={t("LetsWorkTogether")}
        image="/shape4.png"
        marginBottom
      />
      <ContactUs />
    </>
  );
}

export default WorkWithUs;
