import { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import MainContent from "./components/MainContent/MainContent";
import { I18nProvider } from "./i18n";

function App() {
  const storedLanguage = localStorage.getItem("selectedLanguage");
  const [selectedLanguage, setSelectedLanguage] = useState("en");

  useEffect(() => {
    if (storedLanguage) {
      setSelectedLanguage(storedLanguage);
    }
  }, [storedLanguage]);

  return (
    <I18nProvider locale={selectedLanguage}>
      <BrowserRouter>
        <div className="App">
          <Header
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
          <MainContent />
          <Footer
            selectedLanguage={selectedLanguage}
            setSelectedLanguage={setSelectedLanguage}
          />
        </div>
      </BrowserRouter>
    </I18nProvider>
  );
}

export default App;
