import { Routes, Route } from "react-router-dom";
import React from "react";
import Home from "./Home/Home";
import "./MainContent.scss";
import WebCreation from "./Services/WebCreation";
import LogoDesign from "./Services/LogoDesign";
import Portfolio from "./OtherPages/Portfolio/Portfolio";
import WorkWithUs from "./OtherPages/WorkWithUs/WorkWithUs";
import AllServices from "./Services/AllServices/AllServices";
import AboutUs from "./OtherPages/AboutUs/AboutUs";
import ScrollToTop from "../../helpers/components/ScrollToTop";
import IntersectionObserverComponent from "../../helpers/components/IntersectionObserverComponent";

function MainContent() {
  return (
    <main className="mainContent">
      <ScrollToTop />
      <IntersectionObserverComponent />
      <Routes>
        <Route path="/" element={<Home />} exact />
        <Route path="/about" element={<AboutUs />} exact />
        <Route path="/portfolio" element={<Portfolio />} exact />
        <Route path="/workwithus" element={<WorkWithUs />} exact />
        <Route path="/services" element={<AllServices />} exact />
        <Route path="/web" element={<WebCreation />} exact />
        <Route path="/logo" element={<LogoDesign />} exact />
        {/* <Route path="/services/web" element={<WebCreation />} exact /> */}
        {/* <Route path="/services/logo" element={<LogoDesign />} exact /> */}
      </Routes>
    </main>
  );
}

export default MainContent;
