import React from "react";
import "./Buttons.scss";

function ArrowButton({ scrollId }) {
  const handleScroll = () => {
    const scrollSection = document.getElementById(scrollId);
    if (scrollSection) {
      scrollSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <button className="arrowButton grayButton" onClick={handleScroll}>
      <img src="./images/arrowDown.svg" alt="Scroll Down" />
    </button>
  );
}

export default ArrowButton;
