import React from "react";

function GrayButton({ text, type = "button" }) {
  const storedLanguage = localStorage.getItem("selectedLanguage") || "en";
  return (
    <button type={type} className={`btn buttonGray ${storedLanguage}`}>
      {text}
    </button>
  );
}

export default GrayButton;
