import { LOCALES } from "../locales";

const translationKeys = {
  [LOCALES.ROMANIAN]: {
    // NAVIGATION and GENERAL
    Home: "Pagina principală",
    Services: "Servicii",
    Portfolio: "Portofoliu",
    AboutUs: "Despre noi",
    WorkWithUs: "Lucrați cu noi", // lucrează cu noi?
    ContactUs: "Contactați-ne",
    MoreInfo: "Află mai mult",
    // HERO-HOME
    InNeedOfAWebsite: "Aveți nevoie de website?",
    WeWillHelpYou: "Noi vă vom ajuta.",
    // GENERAL INFO
    ProfessionalsInTheField: "Profesioniști în domeniu.",
    // PROBLEMS
    WeWillSolveYourProblems: "Noi vă vom rezolva problemele.",
    TrixmasIsATeamOf:
      "Trixmas este o echipă de dezvoltatori și designeri talentați, care transformă visele și ideile în realitate.",
    // SERVICES
    OurServices: "Serviciile noastre",
    WebsiteCreation: "Crearea website-ului",
    LogoDesign: "Design de logo",
    ForMoreServices: "Pentru mai multe servicii",
    ClickHereMoreServices: "Apasă aici",
    // PROJECTS
    ProjectsWeHaveWorkedOn: "Proiecte la care am lucrat",
    AndOtherMoreHere: "Și mai multe aici",
    // CONTACT FORM
    ForMoreQuestions: "Pentru alte întrebări",
    LeaveAMessage: "Lăsați un mesaj",
    FirstName: "Prenume",
    LastName: "Nume",
    Email: "E-Mail",
    Phone: "Telefon",
    Message: "Mesaj",
    LeaveYourMessageHere: "Lăsați mesajul dumneavoastră aici",
    OurContacts: "Contactele noastre",
    SendMessage: "Trimiteți mesajul",
    GetInTouchWithUs: "Luați legătura cu noi",
    // BLOCKS
    ScrollDown: "Mai departe",
    WebDesignAndDevelopment: "Web design & dezvoltare",
    ElevateYourDigitalPresence:
      "Îmbunătățiți-vă prezența digitală cu soluțiile noastre.",
    MakingTheFaceOfYourBrandShine:
      "Vom face să strălucească fața brandului tău ✨",
    // WHAT DO WE OFFER
    WhatDoWeOffer: "Ce oferim noi?",
    WebDesign1Header: "🌐 Design web personalizat",
    WebDesign1Text:
      "Site-ul dvs. este vitrina dvs. digitală și trebuie să iasă în evidență. Noi creăm designuri web uimitoare, personalizate, care să vă captiveze publicul și să reflecte esența mărcii dumneavoastră.",
    WebDesign2Header: "🔨 Dezvoltare web",
    WebDesign2Text:
      "În spatele fiecărui design frumos se află o funcționalitate puternică. Echipa noastră de dezvoltatori experți se asigură că site-ul dvs. nu numai că arată bine, dar funcționează perfect, oferind experiențe de utilizare fără cusur.",
    WebDesign3Header: "🚀 Design responsiv",
    WebDesign3Text:
      "Într-o eră a navigării mobile, capacitatea de reacție nu este negociabilă. Noi creăm site-uri web care se adaptează la fiecare dispozitiv, asigurându-ne că conținutul dvs. arată excelent indiferent de locul în care se află vizitatorii dvs.",
    WebDesign4Header: "🧩 Abordarea centrată pe utilizator",
    WebDesign4Text:
      "Nevoile și așteptările utilizatorilor dvs. sunt în prim-planul procesului nostru de proiectare. Construim site-uri web care sunt intuitive, ușor de navigat și care mențin vizitatorii implicați.",
    WebDesign5Header: "🔒 Securitate și performanță",
    WebDesign5Text:
      "Înțelegem importanța păstrării prezenței dvs. online în siguranță. Site-urile noastre web sunt fortificate cu cele mai recente măsuri de securitate și optimizăm performanța pentru timpi de încărcare foarte rapizi.",
    WebDesign6Header: "💡 Soluții inovative",
    WebDesign6Text:
      "Afacerea dvs. este unică, iar site-ul dvs. trebuie să reflecte acest lucru. Noi nu creăm doar site-uri web; creăm soluții care să conducă la rezultate, de la simple pagini de destinație la comerț electronic și multe altele.",
    // WHAT DO WE OFFER - LOGO DESIGN
    LogoDesign1Header: "🎨 Design de logo personalizat",
    LogoDesign1Text:
      "Credem în puterea unicității. Designerii noștri talentați vor lucra îndeaproape cu dumneavoastră pentru a crea un logo care să întruchipeze personalitatea și valorile mărcii dumneavoastră.",
    LogoDesign2Header: "🧐 Cercetarea pieței",
    LogoDesign2Text:
      "Este esențial să vă înțelegeți industria și publicul țintă. Efectuăm studii de piață aprofundate pentru a ne asigura că designul logo-ului dvs. nu este doar atrăgător, ci și eficient din punct de vedere strategic.",
    LogoDesign3Header: "🎯 Versatilitate",
    LogoDesign3Text:
      "Logo-ul dvs. trebuie să funcționeze pe diferite platforme și aplicații. Noi creăm logo-uri care arată fantastic atât în format tipărit, cât și în format digital.",
    LogoDesign4Header: "🔑 Memorabilitate",
    LogoDesign4Text:
      "Înțelegem că un logo memorabil este un atu puternic. Ne străduim să creăm logo-uri care să lase o impresie de durată asupra publicului dumneavoastră.",
    // PRICE
    StartingPriceLong: "Toate acestea, la un preț de pornire de ",
    StartingPriceShort: "La un preț de pornire de ",
    only: "doar ",
    // WORK WITH US
    LetsWorkTogether: "Haideți să lucrăm împreună.",
    // Portfolio
    LatestProjects:
      "Câteva dintre cele mai recente proiecte la care am lucrat.",
    LetsConnect: "Hai să ne conectăm și să creăm împreună",
    PortfolioWebDesignAndDevelopment: "Design și dezvoltare web",
    PortfolioWebDesign: "Design web",
    KiwiShopP1:
      "Kiwi Shop este o aplicație web de ecommerce construită de TRIXMAS, dezvoltată pentru a satisface nevoile iubitorilor de animale de companie.",
    KiwiShopP2:
      "Am sporit masiv vânzările magazinului Kiwi Shop (500%) prin mutarea sa online.",
    KiwiShopP3:
      "Utilizatorii pot să navigheze cu ușurință în magazinul zoo online, să schimbe limba preferată, să adauge articolele dorite în coșul de cumpărături și să le verifice.",
    KiwiShopP4:
      "Interfața cu utilizatorul și experiența Kiwi Shop au fost proiectate cu meticulozitate pentru a emana un sentiment de căldură și încântare, în timp ce se aliniază perfect cu preferințele clientului tipic care vizitează magazinul.",
    VictorsP1:
      "Victor dorea un design simplu, minimal, dar funcțional pentru portofoliul său.",
    VictorsP2: "Și asta este ceea ce echipa noastră a executat pentru el.",
    KopeikaP1:
      "Un proiect în cadrul căruia am avut privilegiul de a colabora cu Kopeika Networks OÜ, un jucător important în domeniul serviciilor SMS, cu o prezență solidă în Europa de Est și în țările baltice.",
    KopeikaP2:
      "În calitate de partener dedicat, am contribuit la proiectarea și crearea, bloc cu bloc, a site-ului lor, care convertește clienții.",
    VDClothesP1: "VD Clothes este o aplicație web ecommerce construită de la zero, care prezintă cele mai noi tendințe în materie de modă și stiluri de îmbrăcăminte.",
    VDClothesP2: "Utilizatorii pot naviga cu ușurință prin colecția de articole vestimentare elegante din cadrul aplicației, le pot adăuga în coșul de cumpărături și le pot achita.",
    VDClothesP3: "Cu un design elegant și o interfață ușor de utilizat, VD Clothes promite o experiență de cumpărături plăcută pentru pasionații de modă de pretutindeni.",
    VisitWebsite: "Vizitează",
    // SERVICES PAGE
    SolutionsForYourEveryHeadache: "Soluții pentru toate durerile de cap.",
    VarietyOfServices:
      "Trixmas oferă o varietate de servicii, pentru a satisface nevoile fiecărui client.",
    WebDesignAndDevelopmentParagraph:
      "În peisajul în continuă evoluție al lumii digitale, prezența dvs. online contează mai mult ca niciodată. La TRIXMAS, suntem pasionați de crearea unor experiențe digitale unice și de impact care să vă transforme viziunea în realitate. Serviciile noastre de design și dezvoltare web reprezintă poarta de acces către o prezență online dinamică, atractivă și vizual uimitoare.",
    LogoDesignParagraph:
      "Logo-ul este imaginea mărcii dumneavoastră, prima impresie care surprinde esența afacerii dumneavoastră dintr-o singură privire. La TRIXMAS, suntem specializați în crearea de logo-uri distincte care au un impact de durată. Serviciile noastre de design de logo sunt cheia pentru crearea unei identități vizuale care rezonează cu publicul țintă și vă diferențiază de concurență.",
    // ABOUT US
    ABOUTTR: "DESPRE ",
    YourSuccessIsOurSuccess:
      "Succesul dumneavoastră este succesul nostru. Lucrăm îndeaproape cu dumneavoastră, asigurându-ne că viziunea dumneavoastră este realizată.",
    OurMission: "Misiunea noastră",
    OurMissionParagraph:
      "La TRIXMAS, misiunea noastră este clară: să dăm posibilitatea întreprinderilor să își atingă întregul potențial digital. Înțelegem provocările unice cu care se confruntă întreprinderile într-un mediu online în schimbare rapidă. Scopul nostru este de a oferi soluții care să vă mențină în fața curbei.",
    OurTeam: "Echipa noastră",
    OurTeamP1:
      "Succesul nostru este o dovadă a strălucirii și dedicării echipei noastre.",
    OurTeamP2:
      "Dezvoltatori și designeri talentați care colaborează cu dumneavoastră.",
    OurTeamP3:
      "Folosim cele mai noi tehnologii, cum ar fi React, Redux, Node.js, Figma și AI.",
    OurTeamP4: "Pentru noi, provocările sunt primite cu brațele deschise.",
    WhyUs: "De ce să alegeți TRIXMAS?",
    WhyUsP1: "Competență",
    WhyUsP2: "Abordare centrată pe client",
    WhyUsP3: "Soluții personalizate",
    WhyUsP4: "Accesibilitate",
  },
};

export default translationKeys;
