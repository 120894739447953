import { LOCALES } from "../locales";

const translationKeys = {
  [LOCALES.RUSSIAN]: {
    // NAVIGATION and GENERAL
    Home: "Главная страница",
    Services: "Услуги",
    Portfolio: "Портофолио",
    AboutUs: "О компании", // О нас?
    WorkWithUs: "Работать с нами",
    ContactUs: "Напишите нам",
    MoreInfo: "Подробнее",
    // HERO-HOME
    InNeedOfAWebsite: "Вам нужен веб-сайт?",
    WeWillHelpYou: "Мы вам поможем.",
    // GENERAL INFO
    ProfessionalsInTheField: "Профессионалы в этой области.",
    // PROBLEMS
    WeWillSolveYourProblems: "Мы решим ваши проблемы.",
    TrixmasIsATeamOf:
      "Trixmas - это команда талантливых разработчиков и дизайнеров, воплощающих мечты и идеи в реальность.",
    // SERVICES
    OurServices: "Наши услуги",
    WebsiteCreation: "Создание веб-сайта",
    LogoDesign: "Разработка логотипа", // Дизайн логотипа?
    ForMoreServices: "Для просмотра всех услуг",
    ClickHereMoreServices: "Нажмите здесь",
    // PROJECTS
    ProjectsWeHaveWorkedOn: "Проекты над которыми мы работали",
    AndOtherMoreHere: "А также другие здесь",
    // CONTACT FORM
    ForMoreQuestions: "Для других вопросов",
    LeaveAMessage: "Оставьте сообщение",
    FirstName: "Имя",
    LastName: "Фамилия",
    Email: "Электронная почта",
    Phone: "Телефон",
    Message: "Сообщение",
    LeaveYourMessageHere: "Оставьте ваше сообщение здесь",
    OurContacts: "Наши контакты",
    SendMessage: "Отправить сообщение",
    GetInTouchWithUs: "Свяжитесь с нами",
    // BLOCKS
    ScrollDown: "Далее вниз",
    WebDesignAndDevelopment: "Веб-дизайн & разработка",
    ElevateYourDigitalPresence:
      "Повысьте уровень своего цифрового присутствия с помощью наших решений.",
    MakingTheFaceOfYourBrandShine: "Мы сделаем лицо вашего бренда сияющим ✨.",
    // WHAT DO WE OFFER
    WhatDoWeOffer: "Что мы предлагаем?",
    WebDesign1Header: "🌐 Индивидуальный веб-дизайн",
    WebDesign1Text:
      "Ваш сайт - это ваша цифровая витрина, и он должен выделяться. Мы создаем потрясающие, индивидуальные веб-дизайны, которые привлекают внимание аудитории и отражают суть вашего бренда.",
    WebDesign2Header: "🔨 Веб-разработка",
    WebDesign2Text:
      "За каждым красивым дизайном стоит мощная функциональность. Наша команда опытных разработчиков позаботится о том, чтобы ваш сайт не только отлично выглядел, но и безупречно функционировал, обеспечивая бесперебойную работу пользователей.",
    WebDesign3Header: "🚀 Адаптивный дизайн",
    WebDesign3Text:
      "В эпоху мобильных устройств отзывчивость является обязательным условием. Мы создаем сайты, которые адаптируются к любому устройству, обеспечивая превосходный внешний вид вашего контента независимо от того, где находятся ваши посетители.",
    WebDesign4Header: "🧩 Ориентированный на пользователя подход",
    WebDesign4Text:
      "Потребности и ожидания пользователей находятся в центре нашего внимания при проектировании. Мы создаем сайты, которые интуитивно понятны, удобны в навигации и позволяют заинтересовать посетителей.",
    WebDesign5Header: "🔒 Безопасность и производительность",
    WebDesign5Text:
      "Мы понимаем, как важно обеспечить безопасность вашего присутствия в Интернете. Наши веб-сайты оснащены самыми современными средствами защиты, а их производительность оптимизирована для молниеносной загрузки.",
    WebDesign6Header: "💡 Инновационные решения",
    WebDesign6Text:
      "Ваш бизнес уникален, и ваш сайт должен отражать это. Мы не просто создаем сайты, мы создаем решения, которые приносят результаты: от простых целевых страниц до электронной коммерции и многого другого.",
    // WHAT DO WE OFFER - LOGO DESIGN
    LogoDesign1Header: "🎨 Индивидуальный дизайн логотипа",
    LogoDesign1Text:
      "Мы верим в силу уникальности. Наши талантливые дизайнеры будут тесно сотрудничать с Вами, чтобы создать логотип, который воплотит в себе индивидуальность и ценности Вашего бренда.",
    LogoDesign2Header: "🧐 Исследование рынка",
    LogoDesign2Text:
      "Понимание отрасли и целевой аудитории имеет решающее значение. Мы проводим глубокие исследования рынка, чтобы дизайн логотипа был не только привлекательным, но и стратегически эффективным.",
    LogoDesign3Header: "🎯 Универсальность",
    LogoDesign3Text:
      "Ваш логотип должен работать на различных платформах и в различных приложениях. Мы разрабатываем логотипы, которые отлично смотрятся как в печатном, так и в цифровом формате.",
    LogoDesign4Header: "🔑 Запоминаемость",
    LogoDesign4Text:
      "Мы понимаем, что запоминающийся логотип - это мощный актив. Мы стремимся создавать логотипы, которые оставляют неизгладимое впечатление у аудитории.",
    // PRICE
    StartingPriceLong: "И все это по стартовой цене ",
    StartingPriceShort: "По стартовой цене ",
    only: "всего ",
    // WORK WITH US
    LetsWorkTogether: "Давайте работать вместе.",
    // Portfolio
    LatestProjects:
      "Некоторые из последних проектов, над которыми мы работали.",
    LetsConnect: "Давайте объединяться и создавать вместе",
    PortfolioWebDesignAndDevelopment: "Веб-дизайн и разработка",
    PortfolioWebDesign: "Веб-дизайн",
    KiwiShopP1:
      "Kiwi Shop - это веб-приложение для электронной коммерции, созданное компанией TRIXMAS и предназначенное для удовлетворения потребностей любителей домашних животных.",
    KiwiShopP2:
      "Мы значительно (на 500%) увеличили продажи магазина Kiwi Shop, переведя его в Интернет.",
    KiwiShopP3:
      "Пользователи могут легко ориентироваться в зоомагазине в режиме онлайн, переключать предпочитаемый язык, добавлять желаемые товары в корзину и оформлять заказ.",
    KiwiShopP4:
      "Интерфейс и пользовательский опыт Kiwi Shop были тщательно продуманы, чтобы создать ощущение тепла и восторга и в то же время полностью соответствовать предпочтениям типичного клиента, посещающего магазин.",
    VictorsP1:
      "Виктор хотел получить простой, минималистичный, но работающий дизайн для своего портфолио.",
    VictorsP2: "И это то, что наша команда выполнила для него.",
    KopeikaP1:
      "Проект, в котором нам довелось сотрудничать с компанией Kopeika Networks OÜ, ведущим игроком на рынке SMS-услуг с солидным присутствием в Восточной Европе и странах Балтии.",
    KopeikaP2:
      "В качестве профильного партнера мы участвовали в разработке и создании блок за блоком их сайта, который конвертирует клиентов.",
    VDClothesP1:
      "VD Clothes - это созданное с нуля веб-приложение для электронной коммерции, демонстрирующее последние модные тенденции и стили одежды.",
    VDClothesP2:
      "Пользователи могут легко просматривать коллекцию стильной одежды в приложении, добавлять ее в корзину и оформлять заказ.",
    VDClothesP3:
      "Благодаря элегантному дизайну и простому в использовании интерфейсу, VD Clothes обещает приятный шопинг для любителей моды во всем мире.",
    VisitWebsite: "Посетить",
    // SERVICES PAGE
    SolutionsForYourEveryHeadache: "Решения для всех ваших проблем.",
    VarietyOfServices:
      "Trixmas предоставляет разнообразные услуги, чтобы удовлетворить потребности каждого клиента.",
    WebDesignAndDevelopmentParagraph:
      "В условиях постоянно меняющегося ландшафта цифрового мира ваше присутствие в Интернете имеет как никогда большое значение. В компании TRIXMAS мы увлечены созданием уникальных и впечатляющих цифровых продуктов, которые превращают ваше видение в реальность. Наши услуги в области веб-дизайна и разработки - это ваш путь к динамичному, увлекательному и визуально ошеломляющему присутствию в Интернете.",
    LogoDesignParagraph:
      "Ваш логотип - это лицо вашего бренда, первое впечатление, которое отражает суть вашего бизнеса с одного взгляда. Компания TRIXMAS специализируется на создании оригинальных логотипов, которые оказывают неизгладимое впечатление. Наши услуги по разработке логотипов - это ключ к созданию визуальной идентичности, которая находит отклик у целевой аудитории и выделяет вас на фоне конкурентов.",
    // ABOUT US
    ABOUTTR: "О КОМПАНИИ ",
    YourSuccessIsOurSuccess:
      "Ваш успех - это наш успех. Мы работаем в тесном сотрудничестве с вами, обеспечивая реализацию вашего видения.",
    OurMission: "Наша задача",
    OurMissionParagraph:
      "Наша миссия в TRIXMAS очевидна: дать возможность предприятиям полностью раскрыть свой цифровой потенциал. Мы понимаем, с какими уникальными проблемами сталкиваются компании в быстро меняющейся онлайн-среде. Наша цель - предоставить решения, которые позволят вам опередить конкурентов.",
    OurTeam: "Наша команда",
    OurTeamP1:
      "Наш успех - это свидетельство блестящей работы и преданности делу нашей команды. нашей команды.",
    OurTeamP2: "Талантливые разработчики и дизайнеры, сотрудничающие с вами.",
    OurTeamP3:
      "Мы используем такие новейшие технологии, как React, Redux, Node.js, Figma и AI.",
    OurTeamP4: "Мы принимаем вызовы с распростертыми объятиями.",
    WhyUs: "Почему стоит выбрать TRIXMAS?",
    WhyUsP1: "Компетенция",
    WhyUsP2: "Клиент-ориентированный подход",
    WhyUsP3: "Индивидуальные решения",
    WhyUsP4: "Доступность",
  },
};

export default translationKeys;
