import React from "react";
import { Link } from "react-router-dom";
import "./Logo.scss";

function Logo() {
  return (
    <div className="logo">
      <Link to="/">
        <img className="logoImage" src="./images/Logo.svg" alt="Trixmas Logo" />
        <img
          className="mobileLogoImage"
          src="./images/SmallLogo.svg"
          alt="Trixmas Logo"
        />
      </Link>
    </div>
  );
}

export default Logo;
