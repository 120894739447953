import React from "react";
import { Link } from "react-router-dom";

function TransparentButton({ text, link, closeMobileMenu }) {
  return (
    <Link to={link}>
      <button
        className={"btn transparentButton"}
        onClick={
          closeMobileMenu
            ? () => {
                closeMobileMenu();
              }
            : () => {}
        }
      >
        {text}
      </button>
    </Link>
  );
}

export default TransparentButton;
