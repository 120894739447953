import React, { useState } from "react";
import { Link } from "react-router-dom";

function ProjectItem({ image, name, link }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <article
      className="projects-item column"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <img src={`./images/${image}`} alt={name} />
      {isHovered && (
        <div className="projects-item-overlay">
          <Link to={link}>
            <h3>{name}</h3>
          </Link>
        </div>
      )}
    </article>
  );
}

export default ProjectItem;
