import React from "react";
import { Link } from "react-router-dom";
import { t } from "../../../i18n";

function WhiteButtonScrollDown({ scrollId, link }) {
  const handleScroll = () => {
    const scrollSection = document.getElementById(scrollId);
    if (scrollSection) {
      scrollSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <Link to={link}>
      <button
        className="btn whiteButton"
        onClick={() => {
          handleScroll();
        }}
      >
        {t("ScrollDown")}
      </button>
    </Link>
  );
}

export default WhiteButtonScrollDown;
