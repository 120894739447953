import React from "react";
import { t } from "../../../../i18n";
import "./Problems.scss";
import AnimatedWrapper from "../../../../helpers/components/AnimatedWrapper";

function Problems() {
  return (
    <section className="problems">
      <div className="container">
        <div className="block block-bulb problems-content padding">
          <section className="column column-image">
            <AnimatedWrapper baseClasses={"rightSA"}>
              <img src="./images/shape.png" alt="Decoration" />
            </AnimatedWrapper>
          </section>
          <AnimatedWrapper baseClasses={"column column-text leftSA"}>
            <section className="column column-text">
              <h2>{t("WeWillSolveYourProblems")}</h2>
              <p>{t("TrixmasIsATeamOf")}</p>
            </section>
          </AnimatedWrapper>
        </div>
      </div>
    </section>
  );
}

export default Problems;
