import React from "react";
import { Link } from "react-router-dom";
import AnimatedWrapper from "../../../../helpers/components/AnimatedWrapper";
import { t } from "../../../../i18n";

function PortfolioProjectsItem({
  image,
  header,
  service,
  texts,
  link,
  imageRightColumn,
}) {
  return (
    <article
      className={
        imageRightColumn
          ? "portfolioProjects-item padding reverseColumn1024"
          : "portfolioProjects-item padding"
      }
    >
      {!imageRightColumn && (
        <AnimatedWrapper baseClasses={"column column-image leftSA"}>
          <section className="column column-image">
            <img src={`./images${image}`} alt={header} />
          </section>
        </AnimatedWrapper>
      )}
      <AnimatedWrapper
        baseClasses={
          imageRightColumn
            ? "column column-text leftSA"
            : "column column-text rightSA"
        }
      >
        <section className="column column-text">
          <div className="portfolioProjects-item-top">
            <h2>{header}</h2>
            <Link to={link}>
              <span className="linkDecoration">{t("VisitWebsite")}</span>
            </Link>
          </div>
          <h4>{service}</h4>
          <div className="portfolioProjects-item-texts">
            {texts.map((text) => (
              <p>{text}</p>
            ))}
          </div>
        </section>
      </AnimatedWrapper>
      {imageRightColumn && (
        <AnimatedWrapper baseClasses={"column column-image rightSA"}>
          <section className="column column-image">
            <img src={`./images${image}`} alt={header} />
          </section>
        </AnimatedWrapper>
      )}
    </article>
  );
}

export default PortfolioProjectsItem;
