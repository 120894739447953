import React from "react";
import AnimatedWrapper from "../../../../helpers/components/AnimatedWrapper";
import { t } from "../../../../i18n";

function Team() {
  return (
    <section className="team">
      <div className="container">
        <div className="team-content padding">
          <AnimatedWrapper baseClasses={"bottomSA"}>
            <h2>{t("OurTeam")}</h2>
          </AnimatedWrapper>
          <div className="team-info-list">
            <AnimatedWrapper baseClasses={"team-info-item bottomSA"}>
              <article className="team-info-item">
                <h5>{t("OurTeamP1")}</h5>
              </article>
            </AnimatedWrapper>
            <AnimatedWrapper baseClasses={"team-info-item bottomSA"}>
              <article className="team-info-item">
                <h5>{t("OurTeamP2")}</h5>
              </article>
            </AnimatedWrapper>
            <AnimatedWrapper baseClasses={"team-info-item bottomSA"}>
              <article className="team-info-item">
                <h5>{t("OurTeamP3")}</h5>
              </article>
            </AnimatedWrapper>
            <AnimatedWrapper baseClasses={"team-info-item bottomSA"}>
              <article className="team-info-item">
                <h5>{t("OurTeamP4")}</h5>
              </article>
            </AnimatedWrapper>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Team;
